import { Grid2, Typography } from '@mui/material';
import React from 'react';
import COLORS from '../../../utils/CONSTS/COLORS';
import { type LanguageContent } from '../../../utils/hooks/useLocalContent';
import Box from '../../../components/Box';
import { Check } from '@mui/icons-material';
import sampleDashboard from '../../../images/sampleDashboard.png';

interface HeroSectionProps {
  content: LanguageContent;
}

const HeroSection = ({ content }: HeroSectionProps) => {
  const { hero } = content;
  return (
    <Grid2
      container
      spacing={3}
      sx={{
        minHeight: 500,
      }}
      justifyContent={'center'}
      alignItems={'center'}
      mt={4}
      mb={4}
    >
      <Grid2 size={6}>
        <Typography color={COLORS.WHITE} variant="h4" fontWeight={'600'}>
          {hero?.title}
        </Typography>
        <Typography
          color={COLORS.WHITE}
          variant="body1"
          fontWeight={'500'}
          mt={1}
        >
          {hero?.description}
        </Typography>
        {!!hero?.points?.length &&
          (hero?.points as string[]).map((point, index) => (
            <Box key={String(index)} row center>
              <Check sx={{ color: COLORS.ACCENT }} />
              <Typography
                variant="body1"
                fontWeight={'500'}
                mt={1}
                color={COLORS.WHITE}
                ml={1}
              >
                {point}
              </Typography>
            </Box>
          ))}
        <Box
          flex
          width={300}
          height={58}
          center
          backgroundColor={COLORS.ACCENT}
          borderRadius={10}
          mt={20}
        >
          <Typography color={COLORS.WHITE} variant="body1" fontWeight={'500'}>
            {hero?.actionButton}
          </Typography>
        </Box>
        <Box>
          <Typography color={COLORS.WHITE} variant="body2" mt={1}>
            {hero?.trialText}
          </Typography>
        </Box>
      </Grid2>
      <Grid2 size={6}>
        <Box overflow="hidden" borderRadius={10}>
          <img
            src={sampleDashboard}
            alt="dashboard"
            style={{
              width: '100%',
              height: 'auto',
              boxShadow: '0px 0px 30px 0px rgba(0,0,0,0.5)',
            }}
          />
        </Box>
      </Grid2>
    </Grid2>
  );
};

export default HeroSection;
