/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useMemo, useState } from 'react';
import { Grid2, Typography, useTheme } from '@mui/material';
import Box from '../../components/Box';
import Loading from '../../components/Loading';
import { useUser } from '../../stateManagement/userStateManager';
import useAmazon from '../../utils/hooks/useAmazon';
import { type AmazonProductType } from '../../utils/TYPES';
import useCompareServices from '../../utils/hooks/useCompareServices';
import {
  amazonCategories,
  type AmazonCategoryType,
} from '../../utils/helpers/amazonFeeCalculator';
import useAICategoryFinder from '../../utils/hooks/useAICategoryFinder';
import ProductCard from '../../components/ProductCard';
import AmazonSearchComponent from '../../components/AmazonSearchComponent';
import PaginationComponent from '../../components/PaginationComponent';
import SPACES from '../../utils/CONSTS/SPACES';
import useCompareList from '../../utils/hooks/useCompareList';
import StartCompareListModal from '../../components/modals/StartCompareListModal';
import { start } from 'repl';
import { CompareListState } from '../../stateManagement/CompareListState';
import CompareListComponent from '../../components/CompareListComponent';

const SearchAmazonMarket = (): JSX.Element | null => {
  const { user, permissionStatus, loading } = useUser();

  if (loading) return <Loading size={100} />;

  return (
    <Box flex width="100%">
      <UserDashboard />
    </Box>
  );
};

const UserDashboard = () => {
  const { palette } = useTheme();

  const [searchString, setSearchString] = React.useState('');
  const [selectedCategory, setSelectedCategory] =
    useState<AmazonCategoryType | null>(null);

  const [isStartCompareListModalOpen, setIsStartCompareListModalOpen] =
    useState(false);

  const [page, setPage] = useState(1);

  const {
    amazonProducts,
    searchProduct,
    loading,
    getProductDetail,
    productDetail,
  } = useAmazon();

  const {
    createComparList,
    syncAllCompareLists,
    setCompareListAsActive,
    addRemoveProductToCompareList,
  } = useCompareList({ setIsStartCompareListModalOpen });

  useEffect(() => {
    syncAllCompareLists();
  }, []);

  const { compareServices } = useCompareServices();

  const { findCategoryWithAI, loading: findCategoryWithAILoading } =
    useAICategoryFinder();

  const productsToDisplay = useMemo(
    () => (amazonProducts?.products?.length ? amazonProducts?.products : []),
    [amazonProducts?.products],
  );

  useEffect(() => {
    if (amazonProducts?.products.length) setSearchString(amazonProducts?.query);
  }, [amazonProducts]);

  useEffect(() => {
    setSelectedCategory(amazonProducts?.category as AmazonCategoryType);
  }, [amazonProducts?.category]);

  const [filters, setFilter] = useState<
    Array<{
      name: string,
      selected: boolean,
      ascending: boolean,
      filter: string,
      // eslint-disable-next-line prettier/prettier
    }>
  >([
    {
      name: 'price',
      selected: false,
      ascending: false,
      filter: 'product_price',
    },
    {
      name: 'hottest product',
      selected: false,
      ascending: false,
      filter: 'itemSoldLastMonth',
    },
  ]);

  const productsFiltered = useMemo(() => {
    let filtered: AmazonProductType[] = [...productsToDisplay];
    filters.forEach((filter) => {
      if (filter.selected) {
        filtered = filtered.sort((a, b) => {
          if (filter.ascending) {
            return (a as any)[filter.filter] - (b as any)[filter.filter];
          }
          return (b as any)[filter.filter] - (a as any)[filter.filter];
        });
      }
    });
    return filtered;
  }, [filters, productsToDisplay]);

  return (
    <Box flex column width="100%">
      <AmazonSearchComponent
        searchString={searchString}
        setSearchString={setSearchString}
        searchProduct={searchProduct}
        loading={loading}
        amazonProducts={amazonProducts}
        compareServices={compareServices}
        findCategoryWithAI={findCategoryWithAI}
        findCategoryWithAILoading={findCategoryWithAILoading}
        selectedCategory={selectedCategory}
        setSelectedCategory={setSelectedCategory}
        amazonCategories={amazonCategories}
        page={page}
        setPage={setPage}
      />

      <Box flex column width="100%">
        <Box flex row spaceBetween ml={SPACES?.SPACE_L} mr={SPACES?.SPACE_L}>
          <Box ml={50} row flex>
            {amazonProducts?.query && (
              <Typography variant="h6" fontWeight={'400'} mb={1}>
                Searching for {amazonProducts?.query}
                {!!selectedCategory && ` in ${selectedCategory}`}
              </Typography>
            )}
            {!!productsFiltered?.length && (
              <Typography variant="h6" fontWeight={'400'} mb={1} ml={1}>
                {productsFiltered?.length} products found
              </Typography>
            )}
          </Box>
          <PaginationComponent page={page} setPage={setPage} />
        </Box>
        <Grid2
          gap={1}
          container
          mt={2}
          sx={{ justifyContent: 'center', pr: 3, pl: 3 }}
        >
          {!!productsFiltered?.length &&
            productsFiltered.map((item: AmazonProductType, index) => (
              <ProductCard
                key={item?.asin}
                item={item}
                addRemoveProductToCompareList={addRemoveProductToCompareList}
              />
            ))}
        </Grid2>
      </Box>
      <StartCompareListModal
        isOpen={isStartCompareListModalOpen}
        onClose={() => {
          setIsStartCompareListModalOpen(false);
        }}
        loading={false}
        createComparList={createComparList}
        setCompareListAsActive={setCompareListAsActive}
      />
      <CompareListComponent />
    </Box>
  );
};

export default SearchAmazonMarket;
