import { getFunctions, httpsCallableFromURL } from 'firebase/functions';
import React from 'react';
import { firebaseCall } from '../CONSTS/ENDPOINTS';
import { AmazonSearchState } from '../../stateManagement/AmazonSearchState';

const functions = getFunctions();
const useAICategoryFinder = () => {
  const [aiFoundCategory, setCategory] = React.useState<string | null>(null);
  const [loading, setLoading] = React.useState<boolean>(false);
  const findCategoryWithAI = async (searchTerm: string) => {
    setLoading(true);
    setCategory(null);
    try {
      if (!searchTerm) return;
      const search = httpsCallableFromURL(
        functions,
        firebaseCall('AICategoryFinder'),
      );

      const response = await search({
        searchTerm,
      });
      console.log('responseresponse', response);
      const result = response.data as { category: string };
      AmazonSearchState({ ...AmazonSearchState(), category: result.category });
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  return { findCategoryWithAI, loading };
};

export default useAICategoryFinder;
