/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-shadow */
import React, { useEffect } from 'react';
import { type AmazonProductType } from '../TYPES';
import {
  addDoc,
  collection,
  doc,
  getFirestore,
  onSnapshot,
  query,
  setDoc,
  where,
} from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import {
  CompareListState,
  type CompareListStateType,
} from '../../stateManagement/CompareListState';
import { useReactiveVar } from '@apollo/client';

const auth = getAuth();
const db = getFirestore();

const useCompareList = ({
  setIsStartCompareListModalOpen,
}: {
  setIsStartCompareListModalOpen?: (t: boolean) => void,
}) => {
  const { activeCompareListId, compareLists } =
    useReactiveVar(CompareListState);

  const createComparList = async (name: string) => {
    if (!auth?.currentUser || !name) return;
    console.log('creating compare list');
    try {
      const compareListRef = collection(
        db,
        `users/${auth?.currentUser.uid}/compareLists`,
      );

      // Add a new document with a generated id.
      await addDoc(compareListRef, {
        name,
        products: [],
        active: true,
      });
    } catch (e) {
      console.error(e);
    }
  };

  const syncAllCompareLists = () => {
    // get all compare lists
    if (!auth?.currentUser) return;
    const compareListRef = collection(
      db,
      `users/${auth?.currentUser.uid}/compareLists`,
    );

    const queryRef = query(compareListRef, where('active', '==', true));

    onSnapshot(queryRef, (snapshot) => {
      const data = snapshot.docs
        .map((doc: any) => {
          if (doc.exists()) {
            return {
              id: doc.id,
              products: doc.data()?.products as AmazonProductType[],
              name: doc.data()?.name as string,
              active: !!doc.data()?.active,
            };
          }
          return null;
        })
        .filter(Boolean) as CompareListStateType['compareLists'];
      CompareListState({
        ...CompareListState(),
        compareLists: data,
      });
    });
  };

  const setCompareListAsActive = (id: string) => {
    if (!auth?.currentUser) return;
    CompareListState({
      ...CompareListState(),
      activeCompareListId: id,
    });
    // set a compare list as active
  };

  useEffect(() => {
    if (!activeCompareListId) return;
    CompareListState({
      ...CompareListState(),
      activeCompareList: compareLists?.find(
        (list) => list.id === activeCompareListId,
      ) as CompareListStateType['activeCompareList'],
    });
  }, [activeCompareListId, compareLists]);

  const addRemoveProductToCompareList = (product: AmazonProductType) => {
    if (!auth?.currentUser) return;
    if (!activeCompareListId && setIsStartCompareListModalOpen) {
      setIsStartCompareListModalOpen(true);
      return;
    }
    const compareListRef = doc(
      db,
      `users/${auth?.currentUser.uid}/compareLists/${activeCompareListId}`,
    );

    const exist = CompareListState()?.activeCompareList?.products?.find(
      (p) => p?.asin === product?.asin,
    );

    if (exist) {
      const newProducts =
        CompareListState()?.activeCompareList?.products?.filter(
          (p) => p?.asin !== product?.asin,
        );
      setDoc(
        compareListRef,
        {
          products: newProducts,
        },
        { merge: true },
      );
      return;
    }

    setDoc(
      compareListRef,
      {
        products: [
          ...(CompareListState()?.activeCompareList?.products ?? []),
          product,
        ],
      },
      { merge: true },
    );
    // add to a compare list
  };

  return {
    createComparList,
    syncAllCompareLists,
    setCompareListAsActive,
    addRemoveProductToCompareList,
  };
};

export default useCompareList;
