import React, { useEffect } from 'react';
import {
  Outlet,
  Link,
  useNavigate,
  useParams,
  useRoutes,
  useLocation,
} from 'react-router-dom';
import Box from '../components/Box';
import COLORS from '../utils/CONSTS/COLORS';
import {
  Button,
  Container,
  IconButton,
  Menu,
  MenuItem,
  Typography,
  useTheme,
} from '@mui/material';
import useAuth from '../utils/hooks/useAuth';
import Compare from '@mui/icons-material/Compare';
import LogoutIcon from '@mui/icons-material/Logout';
import SPACES from '../utils/CONSTS/SPACES';
import Logo from '../components/Logo';
import useSyncManagedUsersProfile from '../utils/hooks/useSyncManagedUsersProfile';
import {
  AccountCircle,
  DarkMode,
  Edit,
  EditLocation,
  Home,
  PriceCheck as PriceCheckIcon,
  ProductionQuantityLimits as ProductionQuantityLimitsIcon,
  SavedSearch as SavedSearchIcon,
  Favorite as FavoriteIcon,
  AssuredWorkload,
  Settings,
} from '@mui/icons-material';
import DarkModeSwitch from '../components/DarkModeSwitch';
import { useUser } from '../stateManagement/userStateManager';
import AppBarAndDrawer from './AppBarAndDrawer';
import Divider from '@mui/material/Divider';
import { toLower } from 'lodash';
import useLocalContent, {
  type LanguageContent,
} from '../utils/hooks/useLocalContent';
import { DASHBOARD_SIDEBAR_CONTENT } from '../contents/DASHBOARD_SIDEBAR_CONTENT';
import SwitchLanguage from '../components/SwitchLanguage';
import SwitchMarketplace from '../components/SwitchMarketplace';
import useUserCredit from '../utils/hooks/useUserCredit';

const DashboardLayout = () => {
  const content: LanguageContent = useLocalContent(DASHBOARD_SIDEBAR_CONTENT);

  const { NAVIGATION } = content;
  const { logout } = useAuth();
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const { user, permissionStatus } = useUser();
  const theme = useTheme();

  const { userCredit, syncUserCredit } = useUserCredit();

  useEffect(() => {
    syncUserCredit();
  }, []);

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const { pathname } = useLocation();

  const navigationItems = [
    {
      title: NAVIGATION?.HOME,
      navigation: '',
      icon: (color: string) => <Home sx={{ color }} />,
    },
    {
      title: NAVIGATION?.SEARCH_MARKET,
      navigation: 'search-market',
      icon: (color: string) => <PriceCheckIcon sx={{ color }} />,
    },
    {
      title: NAVIGATION?.MY_PRODUCTS,
      navigation: 'compares',
      icon: (color: string) => <SavedSearchIcon sx={{ color }} />,
    },
    {
      title: NAVIGATION?.MY_FAVORITES,
      navigation: 'compares/favorites',
      icon: (color: string) => <FavoriteIcon sx={{ color }} />,
    },
    {
      title: NAVIGATION?.NICHE_LAB,
      navigation: 'nichelab',
      icon: (color: string) => <ProductionQuantityLimitsIcon sx={{ color }} />,
    },
  ];

  return (
    <Box flex row width="100vw">
      <Box
        backgroundColor={'#062443'}
        style={{
          borderBottom: `1px solid ${theme.palette.customBackground.main}`,
          minWidth: 260,
          height: '100vh',
        }}
      >
        <Box pt={SPACES?.SPACE_S} pb={SPACES?.SPACE_S}>
          <nav>
            <Box>
              <Box
                onClick={() => {
                  navigate('/');
                }}
                mb={SPACES?.SPACE_M}
                pl={SPACES?.SPACE_M}
              >
                <Logo size={190} withFont />
              </Box>

              <div className="auth_navbar_item">
                <Divider sx={{ borderColor: '#23507d' }} />

                <MenuItem>
                  <Box
                    row
                    center
                    mt={5}
                    onClick={() => {
                      navigate('profile');
                    }}
                  >
                    <AccountCircle sx={{ fontSize: 30 }} />
                    <Typography ml={1} mr={1} variant="body1">
                      {user?.displayName}
                    </Typography>
                    {typeof permissionStatus?.subscribedProduct?.name ===
                      'string' &&
                      toLower(
                        permissionStatus?.subscribedProduct?.name as string,
                      ) === 'pro' && (
                        <Box
                          backgroundColor={COLORS.ACCENT_DARK}
                          pl={5}
                          pr={5}
                          borderRadius={4}
                          mr={10}
                        >
                          <Typography variant="body2">
                            {permissionStatus?.subscribedProduct?.name}
                          </Typography>
                        </Box>
                      )}
                    <Edit />
                  </Box>
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    navigate('settings');
                  }}
                >
                  <Settings sx={{ fontSize: 30, mr: 1 }} />
                  <Typography variant="body1">
                    {NAVIGATION?.SETTINGS}
                  </Typography>
                </MenuItem>
                <MenuItem>
                  <AssuredWorkload sx={{ fontSize: 30, mr: 1 }} />

                  <Typography variant="body1" fontWeight={'600'}>
                    {NAVIGATION?.TOKEN}:
                  </Typography>
                  <Typography variant="body1" ml={1}>
                    {userCredit?.totalCredit}
                  </Typography>
                </MenuItem>
                <Divider sx={{ borderColor: '#23507d' }} />
                <MenuItem>
                  {NAVIGATION?.LANGUAGE}:
                  <SwitchLanguage />
                </MenuItem>
                <MenuItem>
                  <DarkMode sx={{ mr: 1 }} />
                  <Typography variant="body2">light</Typography>
                  <DarkModeSwitch />
                  <Typography variant="body2">dark</Typography>
                </MenuItem>
                <Divider sx={{ borderColor: '#23507d' }} />
                {permissionStatus?.hasAccess && (
                  <div>
                    <MenuItem>
                      <Box>
                        <Typography variant="body1" fontWeight={'400'}>
                          Select your market
                        </Typography>
                        <SwitchMarketplace />
                      </Box>
                    </MenuItem>
                    {navigationItems?.map((item, index) => (
                      <MenuItem
                        key={index}
                        onClick={() => {
                          navigate(item.navigation);
                          handleClose();
                        }}
                      >
                        {item.icon(
                          pathname === `/${item.navigation}`
                            ? '#f0c093'
                            : 'white',
                        )}
                        <Typography
                          color={
                            pathname === `/${item.navigation}`
                              ? '#f0c093'
                              : 'white'
                          }
                          sx={{
                            textDecoration:
                              pathname === `/${item.navigation}`
                                ? 'underline'
                                : 'none',
                          }}
                          variant="body1"
                          ml={1}
                        >
                          {item.title}
                        </Typography>
                      </MenuItem>
                    ))}
                  </div>
                )}
                <MenuItem
                  onClick={() => {
                    handleClose();
                    logout();
                  }}
                >
                  <LogoutIcon />
                  <Typography variant="body1" fontWeight={'600'} ml={1}>
                    {NAVIGATION?.LOGOUT}
                  </Typography>
                </MenuItem>
              </div>
            </Box>
          </nav>
        </Box>
      </Box>
      <Box width={'100%'} height={'100vh'} flex overflow="scroll">
        <Outlet />
      </Box>
    </Box>
  );
};

export default DashboardLayout;
