/* eslint-disable @typescript-eslint/no-unsafe-argument */
import { useReactiveVar } from '@apollo/client';
import React from 'react';
import { UserMarketplaceState } from '../../stateManagement/UserMarketplaceState';
import { log } from 'console';
import { AmazonSearchState } from '../../stateManagement/AmazonSearchState';
import { extractNumberFromAmazonItemBought } from '../helpers/extractNumberFromAmazonItemBought';
import { type AmazonProductType } from '../TYPES';
import extractPrice from '../helpers/extractPrice';
import axios from 'axios';
import { getFunctions, httpsCallableFromURL } from 'firebase/functions';
import { firebaseCall } from '../CONSTS/ENDPOINTS';

const functions = getFunctions();

const useAmazon = () => {
  const [productDetail, setProductDetail] = React.useState([]);
  const [loading, setLoading] = React.useState(false);

  const region = useReactiveVar(UserMarketplaceState);

  const amazonProducts = useReactiveVar(AmazonSearchState);

  console.log('regionregionregionregion', region);

  const getBestSellers = async (category: string, page: number) => {
    setLoading(true);
    try {
      const data = null;

      const xhr = new XMLHttpRequest();
      xhr.withCredentials = false;

      xhr.addEventListener('readystatechange', function () {
        if (this.readyState === this.DONE) {
          console.log('HEEREEE', JSON.parse(this?.response));
          AmazonSearchState(JSON.parse(this.response)?.data?.best_sellers);
        }
      });

      xhr.open(
        'GET',
        `https://real-time-amazon-data.p.rapidapi.com/best-sellers?category=${category?.toLowerCase()}&type=BEST_SELLERS&page=${page}&country=${region}`,
      );
      xhr.setRequestHeader(
        'x-rapidapi-key',
        '5e03767350msh99c49dea5537532p18783ejsnfe6cda87f482',
      );
      xhr.setRequestHeader(
        'x-rapidapi-host',
        'real-time-amazon-data.p.rapidapi.com',
      );

      xhr.send(data);
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };

  const searchProduct = async (query: string, page: number) => {
    setLoading(true);
    AmazonSearchState({
      query,
      products: [],
    });
    try {
      // searchAmazonProducts
      const search = httpsCallableFromURL(
        functions,
        firebaseCall('searchAmazonProducts'),
      );

      const response = await search({
        query,
        page,
        region: UserMarketplaceState(),
      });

      AmazonSearchState({
        query,
        products: response?.data as AmazonProductType[],
      });
      setLoading(false);
    } catch (error) {
      console.error('Error fetching Amazon search results:', error);
      setLoading(false);
    }
  };

  const getProductDetail = async (asin: string) => {
    setLoading(true);
    try {
      const data = null;

      const xhr = new XMLHttpRequest();
      xhr.withCredentials = false;

      xhr.addEventListener('readystatechange', function () {
        if (this.readyState === this.DONE) {
          console.log('product detail search', JSON.parse(this?.response));
          setProductDetail(
            JSON.parse(this.responseText)?.data?.products?.slice(0, 10),
          );
          setLoading(false);
        }
      });

      xhr.open(
        'GET',
        `https://real-time-amazon-data.p.rapidapi.com/product-details?asin=${asin}&country=${region}`,
      );
      xhr.setRequestHeader(
        'x-rapidapi-key',
        '5e03767350msh99c49dea5537532p18783ejsnfe6cda87f482',
      );
      xhr.setRequestHeader(
        'x-rapidapi-host',
        'real-time-amazon-data.p.rapidapi.com',
      );

      xhr.send(data);
    } catch (error) {
      console.error(error);
    }
  };

  return {
    amazonProducts,
    loading,
    getBestSellers,
    searchProduct,
    getProductDetail,
    productDetail,
  };
};

export default useAmazon;
