/* eslint-disable @typescript-eslint/no-unnecessary-boolean-literal-compare */
import React, { useEffect } from 'react';
import './App.css';
import { Routes, Route, useNavigate } from 'react-router-dom';
import LoginPage from './pages/Landing/LoginPage';
import RegisterPage from './pages/Landing/RegisterPage';
import './config/firebase';
import useIsLoggedIn from './utils/hooks/useIsLoggedin';
import SearchAmazonMarket from './pages/dashboard/SearchAmazonMarket';
import DashboardLayout from './layouts/DashboardLayout';
import Loading from './components/Loading';
import useSyncUser from './utils/hooks/useUser';
import { ThemeProvider } from '@emotion/react';
import { CssBaseline } from '@mui/material';
import useThemeProvider from './theme';
import UsersLanding from './pages/user/UsersLanding';
import LoggedInAppLayout from './layouts/LoggedInAppLayout';
import UserDetail from './pages/user/UserDetail';
import { useUser } from './stateManagement/userStateManager';
import AEAuthCode from './pages/aEAuthCode/AEAuthCode';
import ComparesLandingPage from './pages/compare/ComparesLandingPage';
import CompareDetailPage from './pages/compare/CompareDetailPage';
import ComparesFavoritePage from './pages/compare/ComparesFavoritePage';
import Subscription from './pages/subscription/Subscription';
import NicheLabLanding from './pages/nicheLab/NicheLabLanding';
import NicheLabSearchDetail from './pages/nicheLab/NicheLabSearchDetail';
import HomeScreen from './pages/homeScreen/HomeScreen';
import Box from './components/Box';
import AppLandingScreen from './pages/app/AppLandingScreen/AppLandingScreen';

function App(): JSX.Element | null {
  const isLoggedIn = useIsLoggedIn();

  const navigate = useNavigate();
  useSyncUser();

  const theme = useThemeProvider();

  const { permissionStatus } = useUser();

  useEffect(() => {
    if (permissionStatus !== undefined && !permissionStatus?.hasAccess) {
      navigate('/');
    }
  }, [permissionStatus]);

  if (
    isLoggedIn === undefined ||
    (isLoggedIn === true && permissionStatus === undefined)
  )
    return null;
  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {isLoggedIn === undefined && (
          <Route path="/" element={<Loading size={100} />} />
        )}
        <Routes>
          {isLoggedIn === false && (
            <Route>
              <Route index element={<HomeScreen />} />
              <Route path="login" element={<LoginPage />} />
              <Route path="register" element={<RegisterPage />} />
            </Route>
          )}
          {isLoggedIn === true && permissionStatus?.hasAccess && (
            <Route path="/" Component={LoggedInAppLayout}>
              <Route Component={DashboardLayout}>
                <Route index Component={AppLandingScreen} />
                <Route path="compares" Component={ComparesLandingPage} />
                <Route path="subscription" Component={Subscription} />
                <Route path="search-market" Component={SearchAmazonMarket} />

                <Route
                  path="compares/favorites"
                  Component={ComparesFavoritePage}
                />
                <Route path="compare/:id" Component={CompareDetailPage} />
                <Route path="users" Component={UsersLanding} />
                <Route path="user/:id" Component={UserDetail} />
                <Route path="ae-autorisation" Component={AEAuthCode} />
                <Route path="nichelab" Component={NicheLabLanding} />
                <Route path="nichelab/:id" Component={NicheLabSearchDetail} />
                <Route
                  path="*"
                  element={
                    <Box center flex width="100%" height="100%">
                      no page
                      <Box
                        onClick={() => {
                          navigate('/');
                        }}
                      >
                        Go back
                      </Box>
                    </Box>
                  }
                />
              </Route>
              <Route path="aEAuthCode" element={<AEAuthCode />} />
            </Route>
          )}
          {isLoggedIn === true && !permissionStatus?.hasAccess && (
            <Route path="/" element={<LoggedInAppLayout />}>
              <Route element={<DashboardLayout />}>
                <Route index element={<Subscription />} />
              </Route>
            </Route>
          )}
        </Routes>
      </ThemeProvider>
    </div>
  );
}

export default App;
