import { Typography } from '@mui/material';
import { truncate } from 'lodash';
import React from 'react';

const CopyText = ({
  text,
  concatLength,
  color,
  ...props
}: {
  text: string,
  concatLength?: number,
  color?: string,
}) => {
  const handleCopy = () => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        alert('Text copied to clipboard!');
      })
      .catch((error) => {
        console.error('Failed to copy text:', error);
      });
  };

  return (
    <Typography
      onClick={handleCopy}
      style={{ cursor: 'pointer' }}
      color={color}
      {...props}
    >
      {!concatLength ? text : truncate(text, { length: concatLength })}
    </Typography>
  );
};

export default CopyText;
