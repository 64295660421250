/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable @typescript-eslint/promise-function-async */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import {
  Button,
  Container,
  Modal,
  Paper,
  TextField,
  Typography,
} from '@mui/material';
import Box from '../../components/Box';
import Loading from '../../components/Loading';
import SPACES from '../../utils/CONSTS/SPACES';
import { useNavigate } from 'react-router-dom';
import useGetComparedRecords from '../../utils/hooks/useGetComparedRecords';
import FavoriteOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined';
import FavoriteIcon from '@mui/icons-material/Favorite';
import COLORS from '../../utils/CONSTS/COLORS';

const ComparesFavoritePage = (): JSX.Element | null => {
  const navigate = useNavigate();

  const { comparedRecords, makeFavorite } = useGetComparedRecords({
    filterType: 'favorites',
  });

  if (comparedRecords && !comparedRecords?.length)
    return <Loading size={100} />;

  return (
    <Container fixed>
      <Box minWidth={1000} pt={SPACES.SPACE_L}>
        {comparedRecords?.length &&
          comparedRecords?.map((record) => {
            return (
              <Box row key={record?.id} flex={1} center>
                <Box
                  onClick={() => {
                    makeFavorite(record?.id as string, !!record?.isFavorite);
                  }}
                  pAll={SPACES.SPACE_XS}
                >
                  {record?.isFavorite ? (
                    <FavoriteIcon
                      style={{
                        color: COLORS.ERROR,
                      }}
                    />
                  ) : (
                    <FavoriteOutlinedIcon
                      style={{
                        color: COLORS.ERROR,
                      }}
                    />
                  )}
                </Box>
                <Box
                  row
                  spaceBetween
                  onClick={() => {
                    navigate(`/compare/${record?.id}`);
                  }}
                  width="100%"
                >
                  <Typography variant="h6">
                    {record?.['search term']}
                  </Typography>
                </Box>
              </Box>
            );
          })}
      </Box>
    </Container>
  );
};

export default ComparesFavoritePage;
