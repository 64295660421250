import React from 'react';
import Box from '../../../components/Box';
import { Grid2, Typography, useTheme } from '@mui/material';
import useLocalContent, {
  type LanguageContent,
} from '../../../utils/hooks/useLocalContent';
import { APP_LANDING_SCREEN } from '../../../contents/APP_LANDING_SCREEN';
import { DASHBOARD_SIDEBAR_CONTENT } from '../../../contents/DASHBOARD_SIDEBAR_CONTENT';
import {
  PriceCheck as PriceCheckIcon,
  ProductionQuantityLimits as ProductionQuantityLimitsIcon,
  SavedSearch as SavedSearchIcon,
  Favorite as FavoriteIcon,
} from '@mui/icons-material';

const AppLandingScreen = () => {
  const content: LanguageContent = useLocalContent(APP_LANDING_SCREEN);
  const contentSideBar: LanguageContent = useLocalContent(
    DASHBOARD_SIDEBAR_CONTENT,
  );

  const { palette } = useTheme();

  const { NAVIGATION } = contentSideBar;

  const navigationItems = [
    {
      title: NAVIGATION?.SEARCH_MARKET,
      navigation: 'search-market',
      icon: (color: string, fontSize: number) => (
        <PriceCheckIcon sx={{ color, fontSize }} />
      ),
    },
    {
      title: NAVIGATION?.MY_PRODUCTS,
      navigation: 'compares',
      icon: (color: string, fontSize: number) => (
        <SavedSearchIcon sx={{ color, fontSize }} />
      ),
    },
    {
      title: NAVIGATION?.MY_FAVORITES,
      navigation: 'compares/favorites',
      icon: (color: string, fontSize: number) => (
        <FavoriteIcon sx={{ color, fontSize }} />
      ),
    },
    {
      title: NAVIGATION?.NICHE_LAB,
      navigation: 'nichelab',
      icon: (color: string, fontSize: number) => (
        <ProductionQuantityLimitsIcon sx={{ color, fontSize }} />
      ),
    },
  ];

  const { topSection } = content;

  return (
    <Box flex flexGrow center column>
      <Box center>
        <Typography textAlign={'center'} variant="h5" fontWeight={'600'}>
          {topSection?.title}
        </Typography>
        <Typography variant="body1" textAlign={'center'}>
          {topSection?.subtitle}
        </Typography>
      </Box>
      <Grid2 gap={1} container justifyContent={'center'} mt={5}>
        {navigationItems.map((item, index) => {
          return (
            <Grid2 key={String(item.title || index)} sx={{ xs: 12, sm: 6 }}>
              <Box
                center
                pAll={20}
                borderRadius={10}
                backgroundColor={palette?.surface?.dark}
                width={400}
                onClick={() => {
                  // navigate(item.navigation);
                }}
              >
                <Box center flex column>
                  <Box>{item.icon(palette?.primary?.main, 50)}</Box>
                  <Typography ml={2} variant="body1">
                    {item.title}
                  </Typography>
                </Box>
              </Box>
            </Grid2>
          );
        })}
      </Grid2>
    </Box>
  );
};

export default AppLandingScreen;
