/* eslint-disable prettier/prettier */
export const amazonCategories = [
  'Books',
  'Beauty, Health and Personal Care',
  'Clothing and Accessories',
  'Jewelry',
  'Consumer Electronics',
  'Everything Else',
  'Media - Books, DVD, Music, Software, Video',
  'Home and Kitchen',
  'Toys and Games',
  'Pet Products',
  'Automotive and Powersports',
  'Sports and Outdoors',
  'Office Products',
  'Grocery and Gourmet',
  'Lawn and Garden',
  'Luggage',
  'Mattresses',
  'Musical Instruments and AV Production',
  'Tools and Home Improvement',
  'Compact Appliances',
  'Footwear',
  'Furniture',
  'Video Games and Gaming Accessories',
  'Watches',
  'Rucksacks and Handbags',
  'Eyewear',
  'Full-Size Appliances',
  'Gift Cards',
  'Amazon Device Accessories',
  'Business, Industrial, and Scientific Supplies',
  'Baby Products',
  'Cycling Accessories',
  'Base Equipment Power Tools',
  'Merchant Fulfilled Services',
  'Amazon Explore',
  'Fine Art',
  'Tires',
  'Lawn Mowers and Snow Throwers',
];

export type AmazonCategoryType =
  | 'Books'
  | 'Beauty, Health and Personal Care'
  | 'Clothing and Accessories'
  | 'Jewelry'
  | 'Consumer Electronics'
  | 'Everything Else'
  | 'Media'
  | 'Home and Kitchen'
  | 'Toys and Games'
  | 'Pet Products'
  | 'Automotive and Powersports'
  | 'Sports and Outdoors'
  | 'Office Products'
  | 'Grocery and Gourmet'
  | 'Lawn and Garden'
  | 'Luggage'
  | 'Mattresses'
  | 'Musical Instruments'
  | 'Tools and Home Improvement'
  | 'Compact Appliances'
  | 'Footwear'
  | 'Furniture'
  | 'Video Games and Gaming Accessories'
  | 'Watches'
  | 'Rucksacks and Handbags'
  | 'Eyewear'
  | 'Full-Size Appliances'
  | 'Gift Cards'
  | 'Amazon Device Accessories'
  | 'Business, Industrial, and Scientific Supplies'
  | 'Baby Products'
  | 'Cycling Accessories';

// Types
interface IAmazonCalculatorProps {
  accountType: 'individual' | 'professional';
  category: AmazonCategoryType;
  price: number;
  deliveryCharges?: number;
  giftWrapCharges?: number;
  country: 'UK' | 'US';
  vatApplicable?: boolean;
  vatRate?: number;
  salesTax?: number;
  itemCost?: number;
  shippingCost?: number;
}

// Define the return type
interface FeeCalculatorReturn {
  referralFee: string;
  closingFee: string;
  perItemFee: string;
  totalFee: string;
  taxDeduction: string;
  totalCost: string;
  totalDeduct: string;
  profit: string;
}

export const amazonFeeCalculator = ({
  accountType, // 'individual' or 'professional'
  category, // Category name as a string
  price, // Product price (includes sales tax or VAT if applicable)
  deliveryCharges = 0, // Optional delivery charges
  giftWrapCharges = 0, // Optional gift wrap charges
  country, // 'UK' or 'US'
  vatApplicable = false, // Boolean: Is VAT applicable?
  vatRate = 0, // VAT rate if applicable (e.g., 20 for 20%)
  salesTax = 0, // Sales tax rate for US (e.g., 10 for 10%)
  itemCost = 0, // Cost to source the product
  shippingCost = 0, // Cost to ship the product to the customer
}: IAmazonCalculatorProps): FeeCalculatorReturn => {
  const referralFeesUK: Record<
    AmazonCategoryType,
    { rate: number, min: number }
  > = {
    Books: { rate: 0.15, min: 0 },
    'Beauty, Health and Personal Care': {
      rate: price > 10 ? 0.15 : 0.08,
      min: 0.25,
    },
    'Clothing and Accessories': { rate: price > 15 ? 0.15 : 0.08, min: 0.25 },
    Jewelry: { rate: price > 225 ? 0.05 : 0.2, min: 0.25 },
    'Consumer Electronics': { rate: 0.07, min: 0.25 },
    'Everything Else': { rate: 0.15, min: 0.25 },
    Media: { rate: 0.15, min: 0.25 },
    'Home and Kitchen': { rate: 0.15, min: 0.25 },
    'Toys and Games': { rate: 0.15, min: 0.25 },
    'Pet Products': { rate: 0.15, min: 0.25 },
    'Automotive and Powersports': { rate: 0.12, min: 0.25 },
    'Sports and Outdoors': { rate: 0.15, min: 0.25 },
    'Office Products': { rate: 0.15, min: 0.25 },
    'Grocery and Gourmet': { rate: 0.15, min: 0.25 },
    'Lawn and Garden': { rate: 0.15, min: 0.25 },
    Luggage: { rate: 0.15, min: 0.25 },
    Mattresses: { rate: 0.15, min: 0.25 },
    'Musical Instruments': { rate: 0.15, min: 0.25 },
    'Tools and Home Improvement': { rate: 0.15, min: 0.25 },
    'Compact Appliances': { rate: 0.15, min: 0.25 },
    Footwear: { rate: 0.15, min: 0.25 },
    Furniture: { rate: 0.15, min: 0.25 },
    'Video Games and Gaming Accessories': { rate: 0.15, min: 0.25 },
    Watches: { rate: 0.15, min: 0.25 },
    'Rucksacks and Handbags': { rate: 0.15, min: 0.25 },
    Eyewear: { rate: 0.15, min: 0.25 },
    'Full-Size Appliances': { rate: 0.15, min: 0.25 },
    'Gift Cards': { rate: 0.15, min: 0.25 },
    'Amazon Device Accessories': { rate: 0.15, min: 0.25 },
    'Business, Industrial, and Scientific Supplies': {
      rate: 0.15,
      min: 0.25,
    },
    'Baby Products': { rate: 0.15, min: 0.25 },
    'Cycling Accessories': { rate: 0.15, min: 0.25 },
  };

  const referralFeesUS: Record<AmazonCategoryType, { rate: number, min: number }> =
    {
      Books: { rate: 0.15, min: 0.3 },
      'Beauty, Health and Personal Care': {
        rate: price > 10 ? 0.15 : 0.08,
        min: 0.3,
      },
      'Clothing and Accessories': {
        rate: price > 20 ? 0.17 : price > 15 ? 0.1 : 0.05,
        min: 0.3,
      },
      Jewelry: {
        rate: price > 250 ? 0.05 : 0.2,
        min: 0.3,
      },
      'Consumer Electronics': { rate: 0.08, min: 0.3 },
      'Everything Else': { rate: 0.15, min: 0.3 },
      Media: { rate: 0.15, min: 0.3 },
      'Home and Kitchen': { rate: 0.15, min: 0.3 },
      'Toys and Games': { rate: 0.15, min: 0.3 },
      'Pet Products': { rate: 0.15, min: 0.3 },
      'Automotive and Powersports': { rate: 0.12, min: 0.3 },
      'Sports and Outdoors': { rate: 0.15, min: 0.3 },
      'Office Products': { rate: 0.15, min: 0.3 },
      'Grocery and Gourmet': { rate: 0.15, min: 0.3 },
      'Lawn and Garden': { rate: 0.15, min: 0.3 },
      Luggage: { rate: 0.15, min: 0.3 },
      Mattresses: { rate: 0.15, min: 0.3 },
      'Musical Instruments': { rate: 0.15, min: 0.3 },
      'Tools and Home Improvement': { rate: 0.15, min: 0.3 },
      'Compact Appliances': { rate: 0.15, min: 0.3 },
      Footwear: { rate: 0.15, min: 0.3 },
      Furniture: { rate: 0.15, min: 0.3 },
      'Video Games and Gaming Accessories': { rate: 0.15, min: 0.3 },
      Watches: { rate: 0.15, min: 0.3 },
      'Rucksacks and Handbags': { rate: 0.15, min: 0.3 },
      Eyewear: { rate: 0.15, min: 0.3 },
      'Full-Size Appliances': { rate: 0.15, min: 0.3 },
      'Gift Cards': { rate: 0.15, min: 0.3 },
      'Amazon Device Accessories': { rate: 0.15, min: 0.3 },
      'Business, Industrial, and Scientific Supplies': { rate: 0.15, min: 0.3 },
      'Baby Products': { rate: 0.15, min: 0.3 },
      'Cycling Accessories': { rate: 0.15, min: 0.3 },
    };

  const closingFeesUK = {
    Books: price < 5 ? 0.5 : 1.0,
    Media: 0.5,
    Other: 0, // No closing fee for non-media products
  };

  const closingFeesUS = {
    Books: 1.8,
    DVD: 1.8,
    Music: 1.8,
    'Software & Computer/Video Games': 1.8,
    'Video Game Consoles': 1.8,
    'Video Game Accessories': 1.8,
    Other: 0, // No closing fee for other categories
  };

  // Select the correct referral fee structure based on country
  const referralFees = country === 'UK' ? referralFeesUK : referralFeesUS;
  const closingFees = country === 'UK' ? closingFeesUK : closingFeesUS;

  // Get the referral fee details for the category
  const categoryFees =
    referralFees[category] || referralFees['Everything Else'];

  // Calculate the total sales price (price + delivery + gift wrap)
  const totalSalesPrice = price + deliveryCharges + giftWrapCharges;

  // Calculate VAT or Sales Tax deduction
  const taxDeduction = vatApplicable
    ? (price * vatRate) / (100 + vatRate) // UK VAT deduction
    : salesTax > 0
      ? (price * salesTax) / (100 + salesTax)
      : 0; // US Sales Tax deduction

  // Calculate the referral fee
  const referralFee = Math.max(
    totalSalesPrice * categoryFees.rate,
    categoryFees.min,
  );

  // Get the closing fee for the category
  const closingFee = country === 'UK' ? 1 : 1.8;

  // Per-item fee for Individual accounts
  const perItemFee =
    accountType === 'individual' ? (country === 'UK' ? 0.75 : 0.99) : 0;

  // Total fees
  const totalFee = referralFee + closingFee + perItemFee;

  // Total cost (item cost + shipping cost)
  const totalCost = itemCost + shippingCost;

  // Total deductions (fees + tax + cost)
  const totalDeduct = totalFee + taxDeduction + totalCost;

  // Calculate profit
  const profit = totalSalesPrice - totalDeduct;

  return {
    referralFee: referralFee.toFixed(2),
    closingFee: closingFee?.toFixed(2),
    perItemFee: perItemFee.toFixed(2),
    totalFee: totalFee.toFixed(2),
    taxDeduction: taxDeduction.toFixed(2),
    totalCost: totalCost.toFixed(2),
    totalDeduct: totalDeduct.toFixed(2), // Sum of all deductions
    profit: profit.toFixed(2), // Final profit after all deductions
  };
};
