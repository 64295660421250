/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-misused-promises */
import {
  Button,
  CircularProgress,
  Modal,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import * as React from 'react';
import Box from '../Box';
import SPACES from '../../utils/CONSTS/SPACES';
import { useFirebaseUpload } from '../../utils/hooks/useFirebaseUpload';
import { LoadingButton } from '@mui/lab';
import { Add, Compare } from '@mui/icons-material';
import { CompareListState } from '../../stateManagement/CompareListState';
import { useReactiveVar } from '@apollo/client';

interface StartCompareListModalProps {
  isOpen: boolean;
  onClose: () => void;
  loading: boolean;
  createComparList: (name: string) => Promise<void>;
  setCompareListAsActive: (id: string) => void;
}

const StartCompareListModal = ({
  isOpen,
  onClose,
  loading,
  createComparList,
  setCompareListAsActive,
}: StartCompareListModalProps) => {
  const theme = useTheme();

  const [name, setName] = React.useState('');

  const { compareLists } = useReactiveVar(CompareListState);

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        className="modal"
        backgroundColor={theme.palette.background.paper}
        width="720px"
        pAll={SPACES?.SPACE_XXL}
        relative
        borderRadius={SPACES?.SPACE_S}
      >
        <Box column height={'100%'} width={'100%'} flex center>
          <Typography variant="h5" color="primary" fontWeight="bold">
            List
          </Typography>
          <TextField
            label="List Name"
            variant="outlined"
            fullWidth
            value={name}
            onChange={(e) => {
              setName(e.target.value);
            }}
            sx={{ mt: 2 }}
          />
          <Box
            onClick={async () => {
              await createComparList(name);
            }}
          >
            <Typography variant="body1" color="textPrimary">
              Start a new list <Add />
            </Typography>
          </Box>
          <Box>
            {!!compareLists?.length &&
              compareLists?.map((list) => {
                return (
                  <Box
                    key={list.id}
                    onClick={() => {
                      setCompareListAsActive(list.id);
                    }}
                  >
                    <Typography>{list.name}</Typography>
                  </Box>
                );
              })}
          </Box>

          <LoadingButton variant="contained" loading={loading} sx={{ mt: 2 }}>
            Start
          </LoadingButton>
        </Box>
      </Box>
    </Modal>
  );
};

export default StartCompareListModal;
