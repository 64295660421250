import {
  ArrowBackIosNewOutlined,
  ArrowForwardIosOutlined,
} from '@mui/icons-material';
import { Typography } from '@mui/material';
import React from 'react';
import Box from './Box';

interface PaginationComponentProps {
  page: number;
  setPage: (page: number) => void;
}

const PaginationComponent = ({ page, setPage }: PaginationComponentProps) => {
  const onNext = () => {
    setPage(page + 1);
  };
  const onPrev = () => {
    if (page > 1) setPage(page - 1);
  };
  return (
    <Box row flex>
      <Box onClick={onPrev}>
        <ArrowBackIosNewOutlined />
      </Box>
      <Typography mr={1} ml={1}>
        {page}
      </Typography>
      <Box onClick={onNext}>
        <ArrowForwardIosOutlined />
      </Box>
    </Box>
  );
};

export default PaginationComponent;
