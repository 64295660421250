import { Box, Button, Typography, useTheme } from '@mui/material';
import React from 'react';
import { useReactiveVar } from '@apollo/client';
import { CompareListState } from '../stateManagement/CompareListState';
import { truncate } from 'lodash';
import useCompareList from '../utils/hooks/useCompareList';

const CompareListComponent = () => {
  const { palette, shadows } = useTheme();
  const { activeCompareList, activeCompareListId } =
    useReactiveVar(CompareListState);

  const { addRemoveProductToCompareList } = useCompareList({});

  if (!activeCompareListId) return null;
  return (
    <Box
      sx={{
        backgroundColor: palette?.customBackground?.dark,
        borderRadius: 2,
        boxShadow: shadows[1],
        padding: 2,
        mb: 2,
        position: 'fixed',
        bottom: 20,
        left: 'auto',
        right: 'auto',
        overflow: 'scroll',
        WebkitBoxShadow: '0px 0px 10px 0px rgba(0,0,0,0.5)',
        maxWidth: '80vw',
        borderColor: palette?.grey[300],
        borderStyle: 'solid',
        borderWidth: 1,
      }}
    >
      <Typography variant="h5" color="primary" fontWeight="bold">
        {activeCompareList?.name}
      </Typography>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
      >
        {!!activeCompareList?.products?.length &&
          activeCompareList?.products?.map((product) => (
            <Box
              key={product?.asin}
              sx={{
                width: 300,
                mr: 2,
              }}
            >
              <Box
                width={100}
                height={100}
                flex={1}
                justifyContent={'center'}
                alignItems={'center'}
                sx={{ backgroundColor: palette?.customBackground?.light }}
                overflow={'hidden'}
              >
                <img
                  src={product?.product_photo}
                  alt="product"
                  style={{
                    width: 100,
                    height: 'auto',
                  }}
                />
              </Box>
              <Typography color="primary">
                {truncate(product?.product_title, { length: 20 })}
              </Typography>
              <Button
                onClick={() => {
                  addRemoveProductToCompareList(product);
                }}
              >
                Remove
              </Button>
            </Box>
          ))}
      </Box>
    </Box>
  );
};

export default CompareListComponent;
