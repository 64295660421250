import React, { useEffect } from 'react';
import Box from './Box';
import SPACES from '../utils/CONSTS/SPACES';
import {
  CircularProgress,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import COLORS from '../utils/CONSTS/COLORS';
import { AutoAwesome, InfoOutlined, Search } from '@mui/icons-material';
import { type AmazonCategoryType } from '../utils/helpers/amazonFeeCalculator';
import { type AmazonProductType } from '../utils/TYPES';
import { type AmazonSearchStateType } from '../stateManagement/AmazonSearchState';

interface AmazonSearchComponentProps {
  searchString: string;
  setSearchString: (searchString: string) => void;
  searchProduct: (searchString: string, page: number) => Promise<void>;
  loading: boolean;
  amazonProducts: AmazonSearchStateType;
  compareServices: (
    searchString: string,
    products: AmazonProductType[],
  ) => Promise<void>;
  findCategoryWithAI: (searchString: string) => Promise<void>;
  findCategoryWithAILoading: boolean;
  selectedCategory: AmazonCategoryType | null;
  setSelectedCategory: (category: AmazonCategoryType) => void;
  amazonCategories: string[];
  page: number;
  setPage: (page: number) => void;
}

const AmazonSearchComponent = ({
  searchString,
  setSearchString,
  searchProduct,
  loading,
  amazonProducts,
  compareServices,
  findCategoryWithAI,
  findCategoryWithAILoading,
  selectedCategory,
  setSelectedCategory,
  amazonCategories,
  page,
  setPage,
}: AmazonSearchComponentProps) => {
  const { palette } = useTheme();
  useEffect(() => {
    if (searchString) searchProduct(searchString, page);
  }, [page]);
  return (
    <Box
      flex
      column
      width="100%"
      // backgroundColor={palette?.surface?.dark}
      pb={SPACES?.SPACE_M}
      mb={SPACES?.SPACE_M}
      mt={SPACES?.SPACE_M}
    >
      <Box
        center
        row
        flex
        width="100%"
        pt={SPACES?.SPACE_M}
        pb={SPACES?.SPACE_M}
      >
        <TextField
          id="outlined-basic"
          label="Search"
          variant="outlined"
          value={searchString}
          onChange={(e) => {
            setSearchString(e.target.value);
          }}
          sx={{
            width: '60%',
            backgroundColor: palette?.surface?.main,
          }}
        />
        <LoadingButton
          onClick={() => {
            setPage(1);
            searchProduct(searchString, 1);
          }}
          loading={loading}
          sx={{ backgroundColor: palette?.primary?.main, ml: 1 }}
          loadingIndicator={
            <CircularProgress size={34} sx={{ color: COLORS?.WHITE }} />
          }
        >
          <Search
            sx={{
              color: loading ? 'transparent' : COLORS?.WHITE,
              fontSize: 35,
              height: 44,
            }}
          />
        </LoadingButton>
        <LoadingButton
          variant="outlined"
          onClick={() => {
            compareServices(
              searchString || amazonProducts?.query,
              amazonProducts?.products,
            );
          }}
          loading={loading}
          sx={{
            color: loading ? 'transparent' : palette.text.primary,
            height: 60,
          }}
        >
          Compare
        </LoadingButton>
      </Box>
      <Box center flex column>
        <Typography
          variant="subtitle1"
          fontWeight={'600'}
          mb={1}
          sx={{ opacity: 0.7 }}
        >
          Select Your Search Category
          <Tooltip title="Category determines market fees and helps calculate your profit ( optional )">
            <InfoOutlined sx={{ ml: 1, fontSize: 18 }} />
          </Tooltip>
        </Typography>
        <Box row center flex>
          <Select
            sx={{ width: 300, backgroundColor: palette?.surface?.main }}
            value={selectedCategory}
            onChange={(e) => {
              setSelectedCategory(e.target.value as AmazonCategoryType);
            }}
            label="Select Your Search Category"
          >
            {amazonCategories.map((category) => (
              <MenuItem key={category} value={category}>
                {category}
              </MenuItem>
            ))}
          </Select>
          <LoadingButton
            variant="outlined"
            onClick={() => {
              findCategoryWithAI(searchString);
            }}
            loading={findCategoryWithAILoading}
            disabled={!searchString}
            sx={{
              color: findCategoryWithAILoading
                ? 'transparent'
                : palette.text.primary,
              height: 56,
              ml: 1,
            }}
          >
            <AutoAwesome sx={{ mr: 1 }} />
            AI Category Finder
          </LoadingButton>
        </Box>
      </Box>
      {/* <FilterComponent filters={filters} setFilters={setFilter} /> */}
    </Box>
  );
};

export default AmazonSearchComponent;
