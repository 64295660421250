/* eslint-disable @typescript-eslint/no-explicit-any */
import { makeVar } from '@apollo/client';

export const userMarketplaceOptions = [
  { code: 'GB', text: 'GB', flag: '🇬🇧' },
  { code: 'US', text: 'US', flag: '🇺🇸' },
];

export interface UserCreditStateType {
  mainCredit: number;
  extraCredit: number;
  totalCredit?: number;
}

export const UserCreditState = makeVar<UserCreditStateType>({
  mainCredit: 0,
  extraCredit: 0,
  totalCredit: 0,
});
