/* eslint-disable @typescript-eslint/no-explicit-any */
import { makeVar } from '@apollo/client';
import { type AmazonProductType } from '../utils/TYPES';

export interface CompareListType {
  id: string;
  name: string;
  products: AmazonProductType[] | [];
  active: boolean;
}

export interface CompareListStateType {
  activeCompareList: CompareListType | null;
  compareLists: CompareListType[] | null;
  activeCompareListId?: string | null;
}

export const CompareListState = makeVar<CompareListStateType>({
  activeCompareList: null,
  compareLists: null,
  activeCompareListId: null,
});
