/* eslint-disable @typescript-eslint/no-explicit-any */
import { makeVar } from '@apollo/client';
import { type AmazonProductType } from '../utils/TYPES';

export interface AmazonSearchStateType {
  query: string;
  products: AmazonProductType[];
  category?: string;
}

export const AmazonSearchState = makeVar<AmazonSearchStateType>({
  query: '',
  products: [],
});
