import { Button, Grid2, Typography, useTheme } from '@mui/material';
import React from 'react';
import { type AmazonProductType } from '../utils/TYPES';
import Box from './Box';
import createAffiliateLink from '../utils/helpers/createAffiliateLink';
import CopyText from './CopyText';
import { truncate } from 'lodash';

interface ProductCardProps {
  item: AmazonProductType;
  addRemoveProductToCompareList?: (product: AmazonProductType) => void;
}

const ProductCard = ({
  item,
  addRemoveProductToCompareList,
}: ProductCardProps) => {
  const { palette } = useTheme();
  return (
    <Grid2
      key={item?.asin}
      size={{ xs: 12, sm: 6, md: 3, lg: 2 }}
      sx={{
        backgroundColor: palette?.surface?.dark,
        borderRadius: 2,
        boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.1)',
        paddingBottom: 2,
        overflow: 'hidden',
        mb: 1,
        justifyContent: 'space-between',
      }}

      // onClick={() => getProductDetail(item?.asin)}
    >
      <Box center flex column spaceBetween height={'100%'}>
        <Box
          overflow="hidden"
          center
          flex
          backgroundColor={'white'}
          pr={10}
          pl={10}
          aspectRatio={1}
        >
          <a
            href={createAffiliateLink(item?.asin)}
            target="_blank"
            rel="noreferrer"
          >
            <img
              src={item.product_photo}
              alt="product"
              loading="lazy"
              style={{
                width: '100%',
                height: 'auto',
              }}
            />
          </a>
        </Box>
        <Box
          pl={15}
          pr={15}
          pt={15}
          flex
          style={{
            flexDirection: 'column',
            justifySelf: 'flex-start',
            alignSelf: 'flex-start',
          }}
        >
          <CopyText
            text={item.product_title}
            concatLength={70}
            color={palette?.primary?.main}
          />
          <Typography mt={2} sx={{ opacity: 0.7 }}>
            {truncate(item?.sales_volume, { length: 50, separator: ' ' })}
          </Typography>
          <Typography mt={2} sx={{ opacity: 0.7 }}>
            {item?.itemSoldLastMonth}
          </Typography>

          {/* <Typography>{item?.delivery}</Typography> */}
        </Box>
        <Box>
          <Typography variant="h6">
            {item?.product_price} {item?.currency}
          </Typography>
        </Box>
        <Box>
          <Button
            variant="contained"
            onClick={() => {
              addRemoveProductToCompareList &&
                addRemoveProductToCompareList(item);
            }}
          >
            Add to list
          </Button>
        </Box>
      </Box>
    </Grid2>
  );
};

export default ProductCard;
