/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable @typescript-eslint/promise-function-async */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import {
  Button,
  Container,
  Modal,
  Paper,
  TextField,
  Typography,
} from '@mui/material';
import Box from '../../components/Box';
import Loading from '../../components/Loading';
import SPACES from '../../utils/CONSTS/SPACES';
import { useUser } from '../../stateManagement/userStateManager';
import { getFunctions, httpsCallableFromURL } from 'firebase/functions';
import useAmazon from '../../utils/hooks/useAmazon';
import { LoadingButton } from '@mui/lab';
import { type AmazonProductType } from '../../utils/TYPES';
import useCompareToEbay from '../../utils/hooks/useCompareToEbay';
import LinearProgressWithLabel from '../../components/LinearProgress';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import useCompareServices from '../../utils/hooks/useCompareServices';
import useGetComparedRecords from '../../utils/hooks/useGetComparedRecords';
import { doc, getDoc, getFirestore } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { flatten, set, values } from 'lodash';
import ProductCard from '../../components/ProductCard';

const db = getFirestore();
const auth = getAuth();

const CompareDetailPage = (): JSX.Element | null => {
  const { id: compareDocId } = useParams();
  const navigate = useNavigate();

  console.log('compareDocId:  ', compareDocId);

  const [productsToDisplay, setProductsToDisplay] = useState<any[]>([]);

  const getFireStoreData = async () => {
    try {
      const response = await getDoc(
        doc(
          db,
          `users/${auth?.currentUser?.uid}/compareProductImages/${compareDocId}`,
        ),
      );
      console.log('response', response?.data()?.result);
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      setProductsToDisplay(flatten(values(response?.data()?.result)));
    } catch (error) {
      console.log('error', error);
    }
  };

  useEffect(() => {
    getFireStoreData();
  }, []);

  if (!productsToDisplay?.length) return <Loading size={100} />;

  return (
    <Container fixed>
      <Box minWidth={1000} pt={SPACES.SPACE_L}>
        <Box row spaceBetween>
          <Typography variant="h6">
            {productsToDisplay?.length} products
          </Typography>
        </Box>
      </Box>
      <Box mt={SPACES.SPACE_M}>
        {!!productsToDisplay?.length &&
          productsToDisplay.map((item: AmazonProductType, index) => (
            <Box key={item?.asin} row>
              <Box mt={SPACES.SPACE_S} width={200}>
                <ProductCard item={item} />
                {/* <a href={item.product_url} target="_blank" rel="noreferrer">
                    <img
                      src={item.product_photo}
                      alt="product"
                      loading="lazy"
                      style={{ width: '20vw' }}
                    />
                  </a>
                  <Typography>{item.product_title}</Typography>
                  <Typography variant="h6">{item.product_price}</Typography>

                  <Typography>{item?.delivery}</Typography>
                  <Typography>{item?.sales_volume}</Typography> */}
              </Box>

              <Box width={'55vw'}>
                {!!item?.AEproducts && (
                  <CheapestProducts
                    cheapestProducts={item?.AEproducts}
                    isUS={true}
                  />
                )}
                {!!item?.ebayProducts && (
                  <Box>
                    <CheapestProducts
                      cheapestProducts={item?.ebayProducts}
                      isUS={true}
                    />
                  </Box>
                )}
              </Box>
            </Box>
          ))}
      </Box>
    </Container>
  );
};

export default CompareDetailPage;

const CheapestProducts = ({
  cheapestProducts,
  isUS,
}: {
  cheapestProducts: any,
  isUS: boolean,
}) => {
  const [imageIndex, setImageIndex] = useState(0);

  return (
    <div>
      <ul
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          listStyle: 'none',
          padding: 0,
        }}
      >
        {!!cheapestProducts?.length &&
          cheapestProducts.map((product: any, index: number) => {
            const isSelected = imageIndex === index;
            return (
              <li
                key={index}
                onClick={() => {
                  setImageIndex(index);
                }}
              >
                <img
                  src={
                    product?.thumbnail ||
                    product?.img?.[0] ||
                    product?.cover ||
                    product?.image?.imageUrl ||
                    product?.product_main_image_url
                  }
                  style={{
                    width: isSelected ? '15vw' : '7vw',
                    borderRadius: 10,
                  }}
                  alt={
                    product?.name || product?.title || product?.product_title
                  }
                />
              </li>
            );
          })}
      </ul>
      <p>
        {cheapestProducts?.[imageIndex]?.name ||
          cheapestProducts?.[imageIndex]?.title ||
          cheapestProducts?.[imageIndex]?.product_title}
      </p>
      <p>
        {!isUS
          ? cheapestProducts?.[imageIndex]?.price?.convertedFromValue
          : cheapestProducts?.[imageIndex]?.price?.value ||
            cheapestProducts?.[imageIndex]?.price ||
            cheapestProducts?.[imageIndex]?.format_price ||
            cheapestProducts?.[imageIndex]?.target_original_price}
      </p>
      <a
        href={
          cheapestProducts?.[imageIndex]?.itemWebUrl ||
          cheapestProducts?.[imageIndex]?.product_detail_url
        }
        target="_blank"
        rel="noreferrer"
      >
        PRODUCT LINK
      </a>
      <h6
        style={{
          color:
            cheapestProducts?.[imageIndex]?.priceDifference > 0
              ? 'green'
              : 'red',
        }}
      >
        {cheapestProducts?.[imageIndex]?.priceDifference}
      </h6>
      <p>
        {cheapestProducts?.[imageIndex]?.sell_points?.[0]?.selling_point_text}
      </p>
    </div>
  );
};
